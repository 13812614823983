@import '../../styles/colors.scss';

.btn {
    cursor: pointer;
    padding: 20px 25px;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.8;
    }

    &.success {
        background: $success;
        color: $light;
    }
    &.danger {
        background: $danger;
        color: $light;
        .icon {
            filter: invert(100%);
        }
    }
    &.primary {
        background: $primary;
        color: $light;
        .icon {
            filter: invert(100%);
        }
    }
    &.secondary {
        background: $secondary;
        color: $light;
    }
    &.light {
        background: $white;
        color: $black;
        border: 2px solid $border-light;
    }
    &.transparent {
        background: none;
        color: $primary;
        padding: 10px 25px;
    }
    &.robot {
        background: $robot;
        color: $white;
        .icon {
            filter: invert(100%);
        }
    }

    &.small {
        margin: 0;
        padding: 0;
        width: fit-content;
        background: none;
        border: none;
    }
}

.icon {
    margin-right: 10px;
    img {
        width: 20px;
    }
}