@import '../../styles/colors.scss';

.saved {
    color: $white;
    width: calc(100% - 20px);
    max-width: 880px;
    background-color: $success;
    padding: 0;
    margin: 0 auto;
    opacity: 0;
    transition: all .3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transform: scale(0);
    font-weight: 700;
    visibility: hidden;
    position: fixed;
    top: 10px;
    left: 50%;
    z-index: 9999;

    img {
        width: 20px;
        margin-right: 10px;
        filter: invert(100%);
    }

    &.active {
        opacity: 1;
        transform: scale(1);
        padding: 15px 20px;
        visibility: visible;
        transform: translate(-50%);
    }
}