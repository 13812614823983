@import '../../../styles/colors.scss';

.new-comment-container {

    padding: 0 0 20px;

    .new-comment-image {
        position: relative;
        width: 100%;
        padding: 0;
        button {
            position: absolute;
            top: 5px;
            right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            background: $white;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            img {
                width: 20px
            }
        }
        .img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            border: 1px solid $border-light;
            margin-bottom: 10px;
        }
    }

    .new-comment-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        button {
            background: $primary;
            color: $white;
            padding: 10px 20px;
            border: none;
            border-radius: 20px;
            font-size: 1rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 5px;

            &.img {
                background-color: $white;
                border: 2px solid $primary;
                color: $primary;
                padding: 5px 15px;

                img {
                    width: 1.5rem;
                    filter: invert(0);
                    margin: 0;
                }
            }

            img {
                width: 1rem;
                filter: invert(1);
                margin-right: 10px;
            }
        }
    }
}

.separator {
    width: 100%;
    height: 1px;
    background: radial-gradient(circle, $light-grey 0%, $white 100%);
    margin: 20px auto;
}