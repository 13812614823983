@import '../../styles/colors.scss';

.alert {
    width: 100%;
    margin: 10px auto 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 20px;
    border-radius: 10px;
    color: $white;
    font-weight: 500;

    &.success {
        background: $success;
    }
    &.error {
        background: $danger;
    }
    &.info {
        background: $secondary;
    }
    &.light {
        background: $white;
        color: $primary;
        padding: 0;
        &.success {
            color: $success;
        }
        &.error {
            color: $danger;
        }
    }

    button {
        margin-left: auto;
        padding-left: 10px;
        cursor: pointer;
        width: 20px;
        aspect-ratio: 1 / 1;
        display: block;
        font-size: 2em;
        background-color: transparent;
        background-image: url('../../assets/icons/close.png');
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        filter: invert(100%);
        border: none;
    }
}