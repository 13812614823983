@import '../../../styles/colors.scss';

.status-label {
    width: calc(100% - 10px);
    padding: 5px 5px 10px 3px;
    color: $primary;
    font-size: 1rem;
    font-weight: 700;
}

.radio-toolbar {
    display: flex;
    width: 100%;
}

.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.radio-toolbar label {
    display: inline-block;
    background-color: $light;
    padding: 20px;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    color: $black;
    transition: all 0.3s ease-in-out;
    margin: 0 5px;
    width: 100%;
    text-align: center;
}

.radio-toolbar label:hover {
    background-color: $primary;
    color: $white;
    cursor: pointer;
}

/* .radio-toolbar input[type="radio"]:focus + label {
    border: 2px dashed #444;
  } */

.radio-toolbar input[type="radio"]:checked+label {
    background-color: $primary;
    border: none;
    color: $white;
}

.infos-status {
    margin: 5px 5px 20px;
    font-size: .8rem;
    padding: 10px;
    color: $light-grey;
    text-align: justify;
    line-height: 1rem;
}