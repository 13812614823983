@import '../../styles/colors.scss';

.album-navigation-container {
    display: flex;

    input[type=text] {
        padding: 15px 0;
        border: none;
        background: none;
        width: calc(100% - 80px);
        font-size: 1.2rem;
        text-align: center;
    }

    button {
        width: 40px;
        background: none;
        border: none;
        cursor: pointer;

        img {
            width: 20px;
            aspect-ratio: 1/1;
        }
    }
}

.album-droppable {
    display: 'grid';
    background: $light;
    border-radius: 20px;
}

.album-draggable {
    padding: 16px;
    position: relative;

    min-height: 100px;

    img {
        width: 100%;
        border-radius: 10px;
    }

    .delete-absolute-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: #eee;
        border: none;
        padding: 7px 6px 5px 6px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        cursor: pointer;

        img {
            width: 20px;
        }
    }

}

.album-container {

    .album-container-form {
        display: flex;
        border: 1px solid $light;
        border-radius: 10px;
        overflow: hidden;

        input[type=text] {
            padding: 15px;
            border: none;
            background: none;
            width: 100%;
            border-radius: 10px 0 0 10px;
        }

        button {
            background: $primary;
            padding: 0 15px;
            border: none;
            cursor: pointer;

            img {
                width: 20px;
                filter: invert(1);
            }
        }
    }

    .album-container-item {
        gap: 10px;

        button {
            position: relative; // Assurez-vous que le bouton est positionné relativement pour que l'overlay puisse être positionné absolument à l'intérieur
            background-color: $white;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 1.2rem;
            font-weight: 500;
            border: none;
            border-radius: 20px;
            padding: 20px;
            aspect-ratio: 1/1;
            cursor: pointer;
            margin-top: 20px;

            background-position: center center;
            background-size: cover;

            img {
                width: 80px;
                height: 80px;
                object-fit: contain;
                filter: invert(1);
                z-index: 2;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 20px;
                z-index: 1;
            }

            h1 {
                position: relative; // Assurez-vous que le texte est au-dessus de l'overlay
                z-index: 2;
                color: $white;
            }
        }
    }
}

// @media (max-width: 768px) {
//     .album-container {
//         .album-container-item {
//             display: block;
//             button {
//                 margin-top: 10px;
//                 max-width: 100%;
//             }
//         }
//     }
// }