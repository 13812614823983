@import '../../../styles/colors.scss';

.new-answer-container {
    
    padding: 0 0 20px 40px;

    button {
        background: $primary;
        color: $white;
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: auto;

        img {
            width: 1rem;
            filter: invert(1);
            margin-right: 10px;
        }
    }
}