@import '../../../styles/colors.scss';

.autocomplete {
    width: 100%;
    margin: 10px auto;
    li {
        list-style: none;
        padding: 10px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        background: none;
        &:hover {
            background: $light
        }
    }
}