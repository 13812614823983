@import '../../../styles/colors.scss';

.setting-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: $light;
    border-radius: 16px;
    gap: 10px;
    margin: 0 auto;
    cursor: pointer;
    transition: all .3s ease-in-out;

    .setting-button-icon {
        width: 50px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $white;
        background-image: url('../../../assets/icons/medaillon.png');
        background-position: center center;
        background-size: 60%;
        background-repeat: no-repeat;
        &.lock {
            background-image: url('../../../assets/icons/lock.png');
        }
        &.view {
            background-image: url('../../../assets/icons/eye.png');
        }
    }
    .setting-button-text {
        width: 100%;
        color: $primary;
        font-weight: 500;
        font-size: 1rem;
        small {
            display: block;
            color: $light-grey;
            font-weight: 400;
            margin-top: 3px;
        }
    }

    .setting-button-arrow {
        width: 50px;
        aspect-ratio: 1/1;
        background-image: url('../../../assets/icons/right.png');
        background-position: center center;
        background-size: 70%;
        background-repeat: no-repeat;
        transition: all .3s ease-in-out;
    }

    &.active {
        opacity: .7;
        .setting-button-arrow {
            transform: rotate(90deg);
        }
    }
}

.setting-collapse {
    width: 100%;
    max-height: 0;
    opacity: 0;
    padding: 0;
    transition: opacity .3s ease-in-out, max-height .3s ease-in-out, padding .3s ease-in-out;
    overflow: hidden;

    // Contenu du collapse
    p {
        color: $light-grey;
        font-size: .9rem;
        b {
            font-weight: 700;
        }
        margin-bottom: 10px;
        &.medium {
            font-weight: 500;
        }
    }

    &.active {
        max-height: 1000px; // Définir une valeur suffisamment grande pour couvrir le contenu réel
        opacity: 1;
        padding: 20px 10px; // Ajuster en fonction de la marge désirée autour du contenu
    }
}

.setting-toggle-container {
    width: 100%;
    padding: 20px 0;
    p {
        color: $primary;
        font-weight: 500;
    }
    .setting-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}

.setting-secondary-medallion {

    .setting-secondary-medallion-container {
        display: flex;
        align-items: center;
        gap: 20px;

        .half-button {
            background: $primary;
            border-radius: 10px;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            width: 150px;
            height: 100px;
            transition: all .3s ease-in-out;
            opacity: 1;
            position: relative;
            cursor: pointer;
            &:hover {
                opacity: .8;
            }
            img {
                width: 25px;
                filter: invert(1);
                margin-bottom: 10px;
            }

            button {
                position: absolute;
                top: 0;
                right: 5px;
                font-size: 1.5rem;
                color: $white;
                background: none;
                padding: 5px;
                border: none;
                cursor: pointer;
            }
        }
    }
}