@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import './styles/colors.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;
}

body {
    font-family: "Rubik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $light;
    &.no-scroll {
        overflow: hidden;
        height: 100%;
    }    
}

.container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.content {
    background: $white;
    width: 100%;
    padding: 20px 20px 100px;
    border-radius: 30px 30px 0 0;
    margin: 20px auto 0;
    min-height: 100vh;
}

h1 {
    font-size: 1.8rem;
    font-weight: 500;
    color: $primary;
    margin: 0;
}

form {
    padding: 0;
    margin: 0 auto;
}

.label-global {
    font-size: 1rem;
    font-weight: 700;
    color: $primary;
    width: 100%;
    display: block;
}

.input-plus {
    width: 100%;
    margin-top: 10px;

    .input-plus-container {
        width: 100%;
        position: relative;

        input {
            width: 100%;
            padding: 20px;
            border: 1px solid $border-light;
            border-radius: 10px;
            font-size: 1rem;
            color: $black;
            transition: 0.3s;
            padding-right: 70px;

            &:focus {
                outline: none;
                border: 1px solid $primary;
            }
        }

        button {
            position: absolute;
            top: 5px;
            right: 5px;
            height: calc(100% - 10px);
            aspect-ratio: 1/1;
            padding: 0;
            border-radius: 10px;
            border: none;
            background-color: $primary;
            cursor: pointer;
            background-image: url('./assets/icons/plus_white.png');
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .link-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0 0;
        position: relative;

        a {
            font-size: 1rem;
            text-decoration: none;
            color: $black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        button {
            background-image: url('./assets/icons/trash.png');
            background-position: center center;
            background-size: 100%;
            background-repeat: no-repeat;
            height: 1rem;
            aspect-ratio: 1/1;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }
    }
}

// margin global

/* Margin Top */
.mt-10 {
    margin-top: 10px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

/* Margin Bottom */
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

/* Margin Left */
.ml-20 {
    margin-left: 20px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

/* Margin Right */
.mr-20 {
    margin-right: 20px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

/* Margin All Sides */
.m-20 {
    margin: 20px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-40 {
    margin: 40px !important;
}


.alert-container-home {
    width: calc(100% - 30px);
    margin: 0 auto;
}


@media screen and (max-width: 500px) {
    form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
}