.spinner-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
        width: 150px;
        aspect-ratio: 1 / 1;
    }

    &.button {
        .spinner {
            width: 50px;
            aspect-ratio: 1 / 1;
        }
    }
}