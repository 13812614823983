@import '../../styles/colors.scss';

.video-uploader-container {
    .video-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .video-item {
        position: relative;
        width: 100%;

        video {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }

        button {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: #eee;
            border: none;
            padding: 7px 6px 5px 6px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            cursor: pointer;
            img {
                width: 20px;
            }
        }
    }
}