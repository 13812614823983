@import '../../styles/colors.scss';

.comment {
    margin-bottom: 40px;
    .comment-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;

        img {
            width: 50px;
            border-radius: 50%;
            background-color: $white;
            object-fit: cover;
            aspect-ratio: 1/1;
        }

        .comment-header-title {
            width: calc(100% - 50px);
            
            button {
                background: none;
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img {
                width: 20px;
            }

            .comment-header-title-name {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 1.2rem;
                font-weight: 700;
                color: $primary;

                span {
                    display: block;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .comment-header-title-date {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.9rem;
                color: $light-grey;
                margin-top: 5px;
                span {
                    display: block;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .comment-moderate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0 0;
        color: $light-grey;
        button {
            background-color: $success;
            border: none;
            color: $white;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 15px;
                filter: invert(1);
                margin-right: 5px
            }
        }
    }

    .comment-text {
        background-color: $light;
        padding: 20px;
        border-radius: 10px;
        margin: 10px auto 5px;
    }

    .comment-image {
        width: 100%;
        margin: 20px 0 0;
        img {
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    .comment-footer {
        padding: 0 20px;
        button {
            background: none;
            color: $primary;
            border: none;
            cursor: pointer;
            &.like {
                color: $danger
            }
        }
    }

    .comment-answer {
        width: calc(100% - 40px);
        margin: 10px 0 0 auto;
        .comment-header {
            img {
                width: 40px;
            }
            .comment-header-title {
                width: calc(100% - 50px);
                .comment-header-title-name  {
                    font-size: 1rem;
                }
                
                img {
                    width: 20px;
                }
            }
        }
        .comment-text {
            background-color: $light;
        }
    }
}