@import '../../styles/colors.scss';

.modal-plan-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    .title {
        width: 100%;
        display: block;
        padding: 10px 0 5px;
    }
}

.modal-plan-body {
    width: 100%;
    display: flex;
    gap: 10px;
    display: flex;


    .modal-plan {
        margin: 0;
        background-color: $primary;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;

        label {
            width: 100%;
            height: 100%;
            display: block;
            cursor: pointer;

            span {
                text-align: center;
                padding: 3px 0;
                display: block;
                color: #fff;
                padding: 15px 10px;
                font-size: 0.9rem;

                small {
                    display: block;
                    margin-top: 10px;
                    word-wrap: break-word;

                    b {
                        font-size: 1.2rem;
                    }
                }

                &.active {
                    background-color: $light;
                    color: $primary;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                img {
                    display: block;
                    width: 40px;
                    filter: invert(1);
                    margin: 0 auto;
                }
            }

            input {
                position: absolute;
                display: none;
                color: #fff !important;

                &:checked+span {
                    background-color: $success;
                }
            }
        }
    }
}