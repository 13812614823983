@import '../../../styles/colors.scss';

.memory-tabs-container {
    width: 100%;
    overflow: hidden;
    position: relative;

    .memory-tab-content {
        width: 33.333%;
        padding: 10px;
    }

    .memory-tabs-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        position: relative;

        .memory-tabs-buttons-dots-container {
            position: relative;
            padding-bottom: 20px;
            width: fit-content;

            button {
                background: none;
                border: none;
                color: $light-grey;
                font-size: 1rem;
                font-weight: 500;
                padding: 10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &.active {
                    color: $primary;
                }
            }
        }

        .dots {
            position: absolute;
            width: 8px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background-color: $primary;
            bottom: 10px;
            left: 14%;
            transition: all 0.3s ease-in-out;

            &._1 {
                left: calc(50% - 4px);
            }

            &._2 {
                left: calc(85% - 2px);
            }

            &.cut {
                left: 20%;

                &._1 {
                    left: 66%;
                }
            }
        }
    }

    .memory-bio {

        // Redéfinition de l'indentation pour Quill
        .ql-adapt {
            color: $black;

            .ql-indent-1 {
                padding-left: 2em;
            }

            .ql-indent-2 {
                padding-left: 3em;
            }

            .ql-indent-3 {
                padding-left: 4em;
            }

            // Ajoutez plus si vous avez des indentations plus profondes

            // Styles pour les listes à puces et numérotées
            ol,
            ul {
                list-style-position: inside;
                padding-left: 0; // Élimine le padding par défaut pour aligner avec le contenu non listé

                li {
                    padding-left: 1rem;
                }
            }
        }

        .memory-links {
            padding: 20px 0;

            a {
                text-decoration: none;
                display: block;
                color: $light-grey;
                font-weight: 400;
                font-size: 1rem;
                margin-top: 10px;
                transition: all 0.3s ease-in-out;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    color: $primary;
                }
            }
        }
    }


}