/* Couleurs de base */
// $primary: #071d49;
// $primary-light: #122E66;
// $secondary: #9087E5;
// $accent: #FFD6DD;
// $light: #EFEEFC;
// $light-grey: #858494;
// $border-light: #E6E6E6;
// $success: #5FC676;
// $danger: #791A2A;
// $white: #fff;
// $black: #111;
// $dark: #0C092A;

$primary: #012E40;
$primary-light: #026773;
$secondary: #3CA6A6;
$accent: #FFD6DD;
$light: #EFEEFC;
$light-grey: #858494;
$border-light: #E6E6E6;
$success: #5FC676;
$danger: #791A2A;
$white: #fff;
$black: #111;
$dark: #001d29;

$robot: #3CA6A6;