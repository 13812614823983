@import '../../../styles/colors.scss';

.memory-media-albums {
    width: 100%;

    .album-cover {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        aspect-ratio: 1/1;
        border-radius: 20px;
        margin-bottom: 20px;
        position: relative;
        background-position: center center;
        background-size: cover;
        border: none;

        &.disabled {
            .disabled-overlay {
                display: flex;
            }
        }

        .disabled-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 20px;
            z-index: 3;
            display: none;
            align-items: center;
            justify-content: center;
            color: $danger;
            font-size: 2rem;
            font-weight: bold;
        }

        img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            filter: invert(1);
            z-index: 2;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 20px;
            z-index: 1;
        }

        h1 {
            position: relative; // Assurez-vous que le texte est au-dessus de l'overlay
            z-index: 2;
            color: $white;
        }
    }
}

.memory-media-gallery {
    margin-bottom: 20px;
}

.memory-media-images-old {
    .image-item {

        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }
}

.memory-media-videos-old {
    .video-item {
        width: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        background-color: $black;

        video {
            width: 100%;
            padding: 0;
            margin: 0;
        }
    }

}

.memory-media-gallery-video {
    width: 100%;

    .video-item {
        position: relative;
        width: 100%;
        margin-bottom: 20px;

        video {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }
}

.css-15ewv6a {
    display: none !important;
}