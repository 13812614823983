@import '../../../styles/colors.scss';

.profile {
    margin: 100px auto 0;
    padding: 100px 15px 20px;
    position: relative;
    .profile-picture {
        position: absolute;
        top: -75px;
        left: calc(50% - 75px);
        width: 150px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $white;
        border: 5px solid $white;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .profile-label {
        width: calc(100% - 30px);
        margin: 0;
        padding: 5px 5px 10px 3px;
        color: $primary;
        font-size: 1rem;
        font-weight: 700;
    }

    .profile-info {
        width: calc(100% - 40px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 20px;
            margin-right: 10px;
        }
        span {
            width: 100%;
            color: $light-grey
        }
    }
}