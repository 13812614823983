@import '../../../styles/colors.scss';

.home-buttons-container {
    width: calc(100% - 30px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .half-button {
        background: $light;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: none;
        border-radius: 20px;
        padding: 20px 10px;

        &.secondary {
            background: $secondary;
            img {
                background: rgba(0, 0, 0, 0.2);
                filter: invert(100%);
            }
            b, small {
                color: $white;
            }
        }

        &:hover {
            opacity: .8;
        }

        b {
            color: $primary;
            padding: 10px 0;
            text-wrap: nowrap;
        }

        small {
            color: $primary;
            text-wrap: nowrap;
        }

        img {
            width: 50px;
            background: $white;
            padding: 10px;
            border-radius: 10px;
        }
    }
}

.alert-container-conversion {
    width: calc(100% - 30px);
    background: $white;
    border: 5px solid $danger;
    color: $danger;
    padding: 20px;
    border-radius: 20px;
    margin: 20px auto 20px;
    b {
        display: block;
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 20px;
    }
}

@media (min-width: 500px) {
    .home-buttons-container {
        justify-content: flex-start;
        .half-button {
            max-width: 200px;
        }
    }
}