.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    border-radius: 8px;
    width: 500px;
    max-width: 80%;
    padding: 20px;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

.modal-body {
    padding: 20px 0;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eaeaea;
    padding-top: 10px;
    gap: 20px;
}
