@import '../../styles/colors.scss';

.form-group {
    padding: 0;
}

.form-navigation {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.bio-button {
    cursor: pointer;
    padding: 20px 25px;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    color: $white;

    &.back {
        background: none;
        color: $primary;
    }

    &.generate {
        background: $success;
        color: $white;
    }
}

.biography-result {
    h4 {
        text-align: center;
        margin: 10px auto;
    }
}

.modal-bio {
    background: white;
    border-radius: 8px;
    width: 95%;
    max-width: 500px;
    height: 95%;
    padding: 0 0 20px;
    position: relative;

    .modal-header-bio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        padding: 20px;
        background: $primary;
        color: $white;
        border-radius: 7px 7px 0 0;
    }
    
    .modal-close-button-bio {
        background: none;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        color: $white;
    }
    
    .modal-body-bio {
        padding: 20px;
        width: 100%;
        height: 100%;
        max-height: calc(100% - 150px);
        overflow-y: auto;

        ul {
            width: calc(100% - 40px);
            margin: 10px auto;
        }

        .form-group {
            input[type="text"] {
                width: 100%;
                padding: 10px 15px;
                border: 1px solid #eaeaea;
                border-radius: 5px;
                margin: 10px 0;
                font-size: 1rem;
                outline: none;

                &:focus {
                    border-color: $primary;
                }
            }
            textarea {
                width: 100%;
                padding: 10px 15px;
                border: 1px solid #eaeaea;
                border-radius: 5px;
                margin: 10px 0;
                font-size: 1rem;
                outline: none;
                min-height: 150px;
                max-height: 400px;
                resize: vertical;

                &:focus {
                    border-color: $primary;
                }
            }
        }
    }
    
    .modal-footer-bio {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #eaeaea;
        gap: 20px;
        padding: 10px 20px 20px;
    }
}