@import '../../styles/colors.scss';

.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../assets/images/login-bg.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.login-title-container{
    width: 100%;
    margin: 0 auto;
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 30px;
    color: $black;
    span {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 10px auto;
    }
}

.login-logo {
    img {
        max-width: 250px;
        width: calc(100% - 30px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
}


.login-card {
    background: $white;
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
    width: calc(100% - 40px);
    max-width: 350px;
    text-align: center;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .login-card-title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 10px auto 20px;
    }
    .login-card-subtitle {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 20px;
        color: $light-grey;
    }
}

.login-content {
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    form {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.login-or {
    width: 100%;
    max-width: 350px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .line {
        width: 100%;
        height: 2px;
        background: $border-light;
        margin: 0 5px;
        display: block;
    }
    .text {
        padding: 0 20px;
        color: $light-grey;
    }
}

.login-infos {
    font-size: .9em;
    color: $primary;
    font-weight: 300;
    width: calc(100% - 30px);
    text-align: center;
}