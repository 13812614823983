@import '../../../styles/colors.scss';

.edit-comment {

    .edit-comment-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        text-align: center;

        button {
            width: 100%;
            background: none;
            border: none;
            font-size: 1rem;
            cursor: pointer;
        }
    }

    .edit-comment-content {
        margin: 0 auto 20px;
    }
}