@import '../../styles/colors.scss';

.history-container {
    

    .subscription-group {
        background: $light;
        padding: 10px;
        margin-bottom: 10px;
        small {
            margin-bottom: 10px;
            display: block;
        }
    }

    .history-item {
        margin: 0 auto 20px;
        width: 100%;
        padding: 0 0 20px;
        border-bottom: 1px solid #dddddd;

        .history-item-date {
            font-size: 0.8em;
            color: $light-grey;
        }

        .history-item-price {
            display: flex;
            justify-content: space-between;
            margin: 10px auto 0;
            span {
                display: inline-block;
                b {
                    margin-left: 10px;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: $primary;
                }
            }
        }

        .history-item-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .history-item-type {
            
                small {
                    display: block;
                    color: $primary;
                }
            }

            .history-item-state {
                display: block;
                border-radius: 5px;
                font-size: 0.8em;
                color: white;
                background: $primary;
                width: fit-content;
                padding: 5px;

                &.active {
                    background: $success;
                }
                &.paid {
                    background: $success;
                }
                &.incomplete {
                    background: $secondary;
                }
                &.incomplete_expired {
                    background: $danger;
                }
                &.canceled {
                    background: $danger;
                }
            }
        }
    }

    .history-item:last-child {
        border-bottom: none;
        padding: 0;
    }
}