@import '../../../styles/colors.scss';


.memory {

    .memory-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        cursor: pointer
    }

    .memory-cover {
        width: 100%;
        height: 300px;
        z-index: 1;
        margin-bottom: -50px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .partner-container {
            position: absolute;
            top: 150px;
            height: 150px;
            width: 50%;
            right: 0;
            z-index: 2;
            padding: 0 5% 30px 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-radius: 30px 30px 0 0;

            img {
                width: 70%;
                height: 70%;
                object-fit: contain;
            }
        }
    }

    .content {
        z-index: 2 !important;
        position: relative;

        .memory-photo {
            position: relative;
            width: 250px;
            height: 250px;
            margin: -150px auto 0;
            display: block;
            background-color: none;
            display: flex;
            align-items: center;
            justify-content: center;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
                -webkit-mask-size: contain;
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-position: center;

                &.default {
                    border: 5px solid $white;
                    border-radius: 50%;
                }
            }
        }
        

        .memory-name {
            padding: 10px 10px 0;
            text-align: center;
        }

        .memory-date {
            text-align: center;
            padding: 20px;
            color: $primary;
            font-size: 1rem;
            font-weight: 500;
        }

        .memory-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $primary;
            border-radius: 20px;
            width: 100%;
            padding: 20px;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background: none;
                border: none;
                width: 100%;
                height: 100%;
                color: $white;
                font-size: 1.2rem;
                font-weight: 500;
                cursor: pointer;

                img {
                    width: 30px;
                    margin-bottom: 10px;
                }
            }

            .divider {
                width: 3px;
                height: 50px;
                background: radial-gradient(circle, $light-grey 0%, $primary 100%);
                margin: 0 20px;
            }
        }
    }

    .memory-footer {
        padding: 10px;
        text-align: center;
        color: $dark;
        font-size: 0.9rem;

        a {
            text-decoration: none;
            color: $primary;
            font-weight: 500;
        }
    }

    .memory-footer-partner {
        padding: 10px;
        text-align: center;
        color: $primary;
        background: $white;
        font-size: 0.9rem;

        a {
            text-decoration: none;
            color: $primary;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 768px) {
    .memory {
        .memory-cover {
            height: 200px;

            .partner-container {
                position: absolute;
                top: 110px;
                height: 90px;
                width: 50%;
                right: 0;
                z-index: 2;
                padding: 0 4% 30px 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                img {
                    width: 60%;
                    height: 60%;
                    object-fit: contain;
                }
            }
        }

        .content {
            .memory-photo {
                width: 200px;
                height: 200px;
                margin-top: -90px;
            }
        }
    }
}