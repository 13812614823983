@import '../../styles/colors.scss';

.progress-bar-container {
    width: calc(100% - 30px);
    height: 10px;
    border-radius: 999px;
    background: #ddd;
    border: none;
    overflow: hidden;

    .progress-bar {
        background: $primary;
        height: 10px;
        border-radius: 999px;
    }
}

.stepOnStep {
    font-size: .8em;
    width: calc(100% - 30px);
    text-align: right;
    margin-top: 50px;
    margin-bottom: 10px;
}