@import '../../styles/colors.scss';

.language-selector-container {
    padding-bottom: 10px;
    button {
        background: $white;
        border: none;
        width: 60px;
        height: 40px;
        padding: 3px;
        margin: 5px;
        opacity: 0.7;
        border-radius: 10px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
        }
        &.active {
            background: $primary;
            opacity: 1;
        }
    }
}