@import '../../../styles/colors.scss';



.toggle-tab-container {
    width: calc(100% - 30px);
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    padding: 10px;
    background: $dark;
    position: relative;

    .toggle-background {
        position: absolute;
        left: 10px;
        top: 10px;
        width: calc(50% - 10px);
        height: calc(100% - 20px);
        background: $secondary;
        z-index: 2;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        &.medias {
            left: 50%;
        }
    }
    .toggle-button {
        padding: 15px;
        font-size: 1.1rem;
        width: 100%;
        text-align: center;
        color: $light-grey;
        cursor: pointer;
        z-index: 3;
        font-weight: 500;
        &.active {
            color: $white;
        }
    }
}

.vc-container {
    width: calc(100% - 30px);
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .item-vc {
        width: 100%;
        background: $primary-light;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 30px 20px;
        border-radius: 20px;
        color: $white;
        cursor: pointer;

        &.visit {
            background: $white;
            color: $primary;
            img {
                filter: invert(0);
            }
        }

        &.view {
            height: 100%;
            padding: calc(1.5rem + 45px) 20px calc(1.5rem + 15px);
            display: block;
            border: 2px solid $white;
            text-align: center;
        }

        b {
            font-size: 3rem;
        }

        img {
            width: 30px;
            position: absolute;
            top: 20px;
            right: 20px;
            filter: invert(100%);
        }

        small {
            display: block;
        }
    }
}

.no-medallion-container {
    background: $white;
    color: $black;
    padding: 10px;
    border-radius: 20px;
    width: calc(100% - 30px);
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    
    p {
        width: 100%;
        font-weight: bold;
        text-align: center;
    }
}

// @media screen and (min-width: 500px) {
//     .vc-container {
//         justify-content: flex-start;
//     }
// }
