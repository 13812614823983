@import '../../styles/colors.scss';

.location-input {
    border: 1px solid $border-light;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    transition: border-color 0.3s ease-in-out;
    font-size: 1em;
    background-color: $white;
    color: $black;
    margin: 10px auto;

    &:focus {
        outline: none;
        border-color: $primary;
    }
}