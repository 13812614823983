@import '../../../styles/colors.scss';

.edit-media {

    .subscription-container {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .badge-sub {
            display: inline-block;
            background: $success;
            color: $white;
            margin: 5px 5px 0 0;
            padding: 5px;
            border-radius: 5px;
            font-size: 0.8rem;
        }
        small {
            display: block;
        }

        .history-close-btn {
            border: none;
            background: none;
            padding: 0 20px;
            cursor: pointer;
            img {
                width: 25px;
            }
        }
    }

    .edit-mask-animal {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        button {
            width: 100%;
            max-width: 100px;
            background: $secondary;
            border-radius: 20px;
            border: none;
            padding: 10px;
            aspect-ratio: 1/1;
            transition: all .3s ease-in-out;
            cursor: pointer;

            &:hover {
                background: $primary;
            }

            img {
                width: 40px;
                filter: invert(1);
            }

            &.active {
                background: $primary;
            }
        }
    }

    .edit-media-cover {
        width: 100%;
        height: 200px;
        background-color: $white;
        border: 1px solid $border-light;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        color: $primary;
        text-align: center;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .edit-media-label {
        font-size: 1rem;
        font-weight: 700;
        color: $primary;
        width: 100%;
    }

    .edit-media-main {
        width: 100%;
        max-width: 450px;
        aspect-ratio: 1/1;
        background-color: $white;
        border: 1px solid $border-light;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        color: $primary;
        text-align: center;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .edit-media-new {
        background-color: $light;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
        img {
            width: 80px;
            height: 80px;
            object-fit: contain;
        }
    }

    .edit-media-delete-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: $white;
        border: none;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        cursor: pointer;
        background-image: url('../../../assets/icons/trash.png');
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 10;
        transition: all .3s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }

    .edit-media-title-absolute {
        position: absolute;
        top: 10px;
        left: 10px;
        color: $white;
        text-shadow: 1px 1px 5px $black;
        font-weight: 500;
        font-size: 1rem;
        // padding: 5px 10px;
        // background-color: rgba(255, 255, 255, 0.8);
        // border-radius: 10px;
    }

    .edit-media-gallery {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        margin: 20px auto;

        // &.video {
        //     grid-template-columns: 1fr;
        // }

        .edit-media-gallery-video {
            width: 100%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            background-color: $black;

            video {
                width: 100%;
                padding: 0;
                margin: 0;
            }

            .image {
                width: 100%;
                height: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
            }
        }

        .edit-media-gallery-image {
            width: 100%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            overflow: hidden;
            position: relative;

            .image {
                width: 100%;
                height: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
            }
        }

        button {
            width: 100%;
            aspect-ratio: 1/1;
            border: none;
            background: $light;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 1.2rem;
            font-weight: 500;
            color: $primary;
            padding: 30px 20px;
            text-wrap: nowrap;
            cursor: pointer;

            .icon {
                width: 25px;
                margin: 0 auto 20px;
            }
        }
    }
    .alert-update-container {
        border: 3px dashed $danger;
        padding: 20px;
        background: #F2F2F2;
        border-radius: 10px;
        margin-top: 20px;
        .alert-update-info {
            color: $black;
            text-align: center;
            padding: 10px 10px 30px;
            // font-weight: bold;
            font-size: 1.5rem;
            button {
                display: block;
                background: none;
                border: none;
                text-decoration: underline;
                color: $black;
                margin: 10px auto 0;
            }
        }
        .alert-update-danger {
            color: $danger;
            text-align: center;
            padding: 0 10px 20px;
            // font-weight: bold;
        }
        .alert-update-button {
            text-align: center;
            padding-top: 10px;
            b {
                display: block;
                color: $danger;
            }
            .alert-img {
                width: 50px;
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 768px) {
    .edit-media {
        .edit-media-gallery {
            grid-template-columns: 1fr 1fr;
            &.video {
                grid-template-columns: 1fr;
            }
            button {
                font-size: 1rem;
            }
        }
    }
}

.media-alert {
    margin-top: 20px;
    width: 100%;
    color: $danger;
    text-align: center;
    background: $light;
    border: 1px solid $border-light;
    padding: 20px;
    border-radius: 20px;
    b {
        display: block;
        margin-top: 10px;
    }
}