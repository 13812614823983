@import '../../styles/colors.scss';

.list-page-container {
    width: 100%;
    margin: 0 auto;

    .list-item {
        width: 100%;
        border: 2px solid $border-light;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        gap: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        margin-bottom: 20px;

        &:hover {
            border: 2px solid $light-grey;
        }

        .list-item-content {
            width: calc(100% - 100px);

            p {
                margin: 0;
                display: inline;
                img {
                    border: none;
                    background: none;
                    width: 20px;
                    vertical-align: bottom;
                    filter: invert(50%);
                }
            }

            .list-item-name {
                color: $primary;
                font-weight: 500;
                font-size: 1.2rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 5px;
            }

            .list-item-desc {
                color: $light-grey;
                font-weight: 400;
                font-size: 1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                span {
                    color: $danger;
                    &.success {
                        color: $success;
                    }
                }
            }
        }

        .right-arrow {
            width: 30px;
            height: 50px;
            background: url('../../assets/icons/right.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        
        img {
            background: $white;
            border: 1px solid $border-light;
            border-radius: 10px;
            width: 50px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
    }
}