@import '../../../styles/colors.scss';

.chart-container {
    max-height: 400px;
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .chart-container {
        max-height: 300px;
    }
}