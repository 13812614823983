@import '../../styles/colors.scss';

.header-container {
    width: calc(100% - 30px);
    max-width: 900px;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100% - 80px);

        &.right-ajust {
            padding-right: 30px;
        }

        .header-title {
            font-size: 1.5rem;
            font-weight: 500;
            color: $white;
            margin-bottom: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            text-align: center;
        }
        .header-subtitle {
            color: $white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            text-align: center;
        }
    }
}