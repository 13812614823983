@import '../../styles/colors.scss';



.memory-land {
    background-image: url('../../assets/images/login-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .memory-logo {
        img {
            max-width: 250px;
            width: calc(100% - 30px);
        }
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 30vh;
        &.noMargin {
            margin-bottom: 10vh;
        }
    }

    .memory-card {
        background: $white;
        border-radius: 10px;
        padding: 20px;
        margin: 0 auto;
        width: calc(100% - 40px);
        max-width: 500px;
        text-align: center;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        .memory-card-title {
            font-size: 1.5rem;
            font-weight: 600;
            margin: 10px auto 20px;

            &.green {
                color: $success
            }
            &.red {
                color: $danger
            }
        }
        .memory-card-subtitle {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 20px;
            color: $light-grey;
            &.red {
                color: $danger;
                margin-bottom: 0;
            }
            span {
                color: $primary;
                cursor: pointer;
            }
        }
        .memory-card-half {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }
    }
}

.memory-card-partner {
    background: $white;
    color: $black;
    padding: 20px 10px;
    border-radius: 10px;
    width: calc(100% - 40px);
    max-width: 500px;
    margin-top: -100px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 0.9rem;

    b {
        display: block;
        margin: 10px auto;
        font-size: 1rem;
    }
    small {
        display: block;
    }
    img {
        max-width: 200px;
        width: 100%;
        margin: 10px auto;
    }
}