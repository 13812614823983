@import '../../styles/colors.scss';

.profile-banner-container {
    width: calc(100% - 30px);
    margin: 20px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        width: 50px;
        object-fit: cover;
        aspect-ratio: 1/1;
        border-radius: 100%;
        border: 2px solid $white;
        cursor: pointer;
        background-color: $white;
    }

    .profile-banner-name {
        small {
            display: block;
            color: $accent;
            font-weight: 500;
            padding: 0 0 10px;
            font-size: .9rem;
        }
        color: $white;
        font-weight: 600;
        font-size: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}