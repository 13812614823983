@import '../../styles/colors.scss';


.label {
    width: 100%;
    margin-bottom: 10px;
    display: block;
    span {
        color: $primary;
        font-size: 1em;
        font-weight: 700;
        padding: 5px 5px 5px 3px;
        display: block;
    }

    input[type="date"] {
        max-width: 100%;
        padding: 20px 10px;
        font-size: .8rem;
    }

    .input {
        border: 1px solid $border-light;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        transition: border-color 0.3s ease-in-out;
        font-size: 1em;
        background-color: $white;
        color: $black;

        &.icon {
            padding: 20px 20px 20px 50px;
            &.email {
                background-image: url('../../assets/icons/mail.png');
                background-size: 20px;
                background-position: 15px center;
                background-repeat: no-repeat;
            }
            &.password {
                background-image: url('../../assets/icons/lock.png');
                background-size: 20px;
                background-position: 15px center;
                background-repeat: no-repeat;
            }
            &.user {
                background-image: url('../../assets/icons/user.png');
                background-size: 20px;
                background-position: 15px center;
                background-repeat: no-repeat;
            }
        }
        &:focus {
            outline: none;
            border-color: $primary;
        }
    }
}