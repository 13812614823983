@import '../../styles/colors.scss';

.licence-container {
    background: $white;
    color: $black;
    padding: 20px;
    border-radius: 20px;
    width: calc(100% - 30px);
    margin: 20px auto 0;

    h2 {
        text-align: center;
        font-size: 1rem;
        color: $danger;
    }

    .licence-list {
        width: 100%;
    }

    .licence-list-item {
        width: 100%;
        padding: 0;
        .licence-list-item-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            small {
                display: block;

                &.PAUSED {
                    color: $danger;
                }
                &.ACTIVE {
                    color: $success;
                }
            }
        }
        .licence-list-item-button {
            display: flex;
            justify-content: space-between;
            padding: 10px 0 0;
            align-items: center;
            a {
                text-decoration: underline;
                color: $primary;
            }
            button {
                background: $primary;
                color: $white;
                border: none;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background: $primary-light;
                }

                &.outline {
                    background: $white;
                    color: $danger;
                    border: 1px solid $danger;
                    &:hover {
                        background: $danger;
                        color: $white;
                    }
                
                }
            }
        }
    }
}

.contact-owner {
    margin: 20px auto 0;
    padding: 10px;
    text-align: center;
    font-size: .9rem;
    color: $white;
    background: $primary-light;
    border-radius: 5px;
}