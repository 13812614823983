@import '../../styles/colors.scss';

.preview-ytb {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -10px;
    margin-bottom: 10px;

    .youtube-player-preview {
        border-radius: 10px;
        overflow: hidden;
        width: 100% !important;
        max-width: 360px !important;
        height: auto !important;
        aspect-ratio: 16 / 9 !important;
    }
}

.video-item {
    position: relative;
    
    .youtube-player {
        border-radius: 10px;
        overflow: hidden;
        width: 100% !important;
        height: auto !important;
        aspect-ratio: 16 / 9 !important;
        margin-bottom: 20px;
    }
}