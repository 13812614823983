@import '../../../styles/colors.scss';

.edit-info {

    .edit-info-label {
        font-size: 1rem;
        font-weight: 700;
        color: $primary;
        width: 100%;
        display: block;
    }

    .edit-info-dateOfBirth {
        width: 100%;
        overflow: hidden;
        display: flex;
        gap: 10px;
    }

    .edit-info-location {
        padding: 0;

        .edit-info-location-alert {
            color: $danger;
            text-align: center;
            font-size: 1rem;
            font-weight: 500;
            margin-top: 10px;
        }
    }

    .edit-info-location-ajust {
        border-radius: 10px;
        overflow: hidden;
    }

    .quill {
        margin-top: 10px;
        border: 1px solid $border-light;
        border-radius: 10px;
        padding: 5px 0;

        .ql-editor {
            min-height: 400px;
            max-height: 600px;
        }

        .ql-snow {
            border: none;
        }
    }
}

.spinner-absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}