@import '../../../styles/colors.scss';

.user-table {
    width: 100%;

    th {
        text-align: left;
    }
    td {
        padding: 10px 0;
    }
}

.edit-user {

    .edit-header {
        display: flex;
        margin-bottom: 20px;

        .edit-header-info {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
        }

        button {
            color: $danger;
            font-size: 2rem;
            font-weight: bold;
            border: none;
            background: none;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: darken($danger, 10%);
            }
        }
    }
}